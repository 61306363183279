// JsFromRoutes CacheKey 5d32d576f814bbc96f0caef3f1aa3b06
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@consumer/services/api'

export default {
  about: /* #__PURE__ */ definePathHelper('get', '/about'),
  press: /* #__PURE__ */ definePathHelper('get', '/press'),
  terms: /* #__PURE__ */ definePathHelper('get', '/legal'),
  tos: /* #__PURE__ */ definePathHelper('get', '/terms'),
  privacy: /* #__PURE__ */ definePathHelper('get', '/privacy'),
  giftagreement: /* #__PURE__ */ definePathHelper('get', '/giftagreement'),
  howItWorks: /* #__PURE__ */ definePathHelper('get', '/how-it-works'),
  demo: /* #__PURE__ */ definePathHelper('get', '/demo'),
  index: /* #__PURE__ */ definePathHelper('get', '/'),
  root: /* #__PURE__ */ definePathHelper('get', '/'),
}
